//
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change body background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #f6f9fc;
$font-family-sans-serif:      "Poppins", sans-serif !default;
$primary:                     #e42542 !default;
